import { useRouteLoaderData } from '@remix-run/react';

import type { loader } from '~/root';
import type { SessionData } from '~/types/sessionData';
//TODO: test if this works
export function useUser(): SessionData | null {
  const data = useRouteLoaderData<typeof loader>('root');
  if (!data || !data.userSession) return null;
  return data.userSession;
}
